'use client';

import React, { useContext, createContext, useState, ReactNode, useCallback, useLayoutEffect } from 'react';
import { LocaleConstants } from './constants/LocaleConstants';
import { DEFAULT_LOCALE, i18nKey, isSupportLocale, Locale } from './types/locale';
import { RESOURCES } from './resources';


type TranslationContextProps = {
	locale: Locale;
	setLocale: (value: Locale) => void;
	loading: boolean;
	t: (key: i18nKey) => string;
}

const TranslationContext = createContext<TranslationContextProps>({
	locale: DEFAULT_LOCALE,
	setLocale: (value: Locale) => { },
	loading: true,
	t: (key: i18nKey) => ''
}
);


const initLocle = (): Locale => {
	if (typeof window !== 'undefined') {
		const locale = window.localStorage.getItem(LocaleConstants.LOCALSTORAGE_KEYS.DEFAULT_LOCAL) ?? DEFAULT_LOCALE;

		if (isSupportLocale(locale)) {
			return locale;
		}
	}

	return DEFAULT_LOCALE;
}


// プロバイダーコンポーネント
export const TranslationProvider: React.FC<{ children?: ReactNode }> = ({
	children,
}) => {
	const [locale, setLocale] = useState<Locale>(DEFAULT_LOCALE);
	const [loading, setLoading] = useState(true);

	const translate = useCallback(
		(key: i18nKey) => {
			return RESOURCES[locale][key];
		},
		[locale]
	);

	useLayoutEffect(() => {
		setLocale(initLocle());
		setLoading(false);
	}, []);

	return (
		<TranslationContext.Provider value={
			{
				locale,
				loading,
				setLocale,
				t: translate,
			}
		}>
			{children}
		</TranslationContext.Provider>
	);
};

// コンテキストを使用するためのフック
export const useTranslation = (): TranslationContextProps =>
	useContext<TranslationContextProps>(TranslationContext);

export default TranslationProvider;